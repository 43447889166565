angular.module('fingerink')
    .factory('templateService', ['$http', 'session', function ($http, session) {
        var sdo = {    
            getURL : function(file, page){
                return $http({
                    cache: false,
                    method: 'GET',
                    url: 'templates/URLFile/' + file +((page != null) ? '/' + page : ""),
                    crossDomain: true
                });
            },
            
            createPlantilla: function (plantilla) {
                return $http({
                    method: 'POST',
                    url: 'templates',
                    data: plantilla,
                    crossDomain: true
                });
            },
            
            createPlantillaFromFirma: function (idFirma) {
                return $http({
                    method: 'POST',
                    url: 'templates/fromFirma/'+idFirma,
                    crossDomain: true
                });
            },
           
            deleteTemplate: function(template){
                return $http({
                    method: 'DELETE',
                    url: 'templates/'+template,
                    crossDomain: true
                });
            },
            getPlantillas: function (params) {
                return $http({
                    method: 'GET',
                    url: 'templates' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },
            
            getPlantilla: function(id){
                 return $http({
                    method: 'GET',
                    url: 'templates/'+id,
                    crossDomain: true
                });
            },
            
            //Categorias del template
            createTemplateCategoria: function (firmac) {
                return $http({
                    method: 'POST',
                    url: 'templates/categories/',
                    crossDomain: true,
                    data: firmac
                });
            },
            getTemplateCategories: function (params) {
                return $http({
                    method: 'GET',
                    url: 'templates/categories/' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },
            deleteTemplateCategories: function (id) {
                return $http({
                    method: 'DELETE',
                    url: 'templates/categories/' + id,
                    crossDomain: true
                });
            },
            
            moveTemplatetoCategory: function (grupo) {
                return $http({
                    method: 'PUT',
                    url: 'templates/movetocategory/' + grupo.id,
                    crossDomain: true,
                    data: grupo
                });
            }                      
            
        };
        return sdo;
}]);
